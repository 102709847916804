.home {
  .image-jumbotron {
    height: 70%;

    @media only screen and (max-width: 600px) {
      height: 50%;
    }

    @media only screen and (min-width: 600px) {
      height: 50%;
    }

    .main-text {
      //letter-spacing: 3px;
      font-size: 5vw;
    }

    .sub-text {
      //letter-spacing: 2px;
      font-size: 3vw;
    }
  }
}
