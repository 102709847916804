$headingFontColor: #333333;
$headingFontFamily: "Raleway", sans-serif;
$defaultFontFamily: "Raleway", sans-serif;
$paragraphColor: #333333;
$labelColor: #b2b4b2;

$swPrimaryColor: #f99f1b;
$swSecondaryColor: grey;
$swTertiaryColor: $swSecondaryColor;
$swSectionFeatureColor: #4d9ca4;
$swContactColor: #f99f1b;
$swQuintenaryColor: #f5f5f5;

$swBtnPrimaryColor: #f99f1b;
$swBtnPrimaryHoverColor: #2aa4dd;
$swBtnSecondaryColor: #30a5df;
$swBtnSecondaryHoverColor: $swTertiaryColor;

$swAltColor: #179ea3;
$swErrorColor: #ad0c0c;

$navBarBgColor: #ffffff;
$navBarItemBgColor: #363948;
$navBarItemColor: #ffffff;
$navBarItemHoverColor: #6060d6;
$navBarDropDownMenuBgColor: #2c368f;
