@import "assets/styles/variables.scss";

.text-title {
  display: inline-block;

  .bottom-border {
    margin: auto;
    margin-top: 10px;
    width: 206px;
    border-bottom: 3px solid $swPrimaryColor;
  }
}
