@import "assets/styles/variables.scss";

.contact {
  .nav-link {
    cursor: pointer;
    font-weight: bold;
    text-transform: uppercase;
    color: $swPrimaryColor !important;
  }
}
