.image-header {
  height: 40vh;
  min-height: 300;
  position: relative;

  // @mixin responsive-styles {
  //   @media only screen and (max-width: 600px) {
  //     height: 30vh;
  //     min-height: 150;
  //   }

  //   @media only screen and (min-width: 600px) {
  //     height: 40vh;
  //     min-height: 200;
  //   }

  //   @media only screen and (min-width: 768px) {
  //     height: 70vh;
  //     min-height: 300;
  //   }
  // }

  // @include responsive-styles;

  .image-container {
    height: 40vh;
    min-height: 400;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    filter: brightness(70%);
    z-index: 1 !important;

    // @include responsive-styles;
  }

  .children-container {
    z-index: 2 !important;
    height: 40vh;
    min-height: 400;
    position: absolute;
    top: 0;

    // @include responsive-styles;
  }

  .text-container {
    @extend .children-container;

    h1 {
      color: #fff !important;
      font-weight: 700 !important;
      //letter-spacing: 5px !important;
      filter: brightness(100%) !important;
      text-transform: uppercase !important;
      text-align: center;
      text-shadow: 0px 0px 5px rgba(0, 0, 0, 1);
    }
  }
}

.image-header-hero {
  height: 70vh;
  min-height: 300;
  position: relative;

  @mixin responsive-styles {
    @media only screen and (max-width: 600px) {
      height: 30vh;
      min-height: 150;
    }

    @media only screen and (min-width: 600px) {
      height: 40vh;
      min-height: 200;
    }

    @media only screen and (min-width: 768px) {
      height: 70vh;
      min-height: 300;
    }
  }

  @include responsive-styles;

  .image-container {
    height: 70vh;
    min-height: 300;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    filter: brightness(70%);
    z-index: 1 !important;

    @include responsive-styles;
  }

  .children-container {
    z-index: 2 !important;
    height: 70vh;
    min-height: 300;
    position: absolute;
    top: 0;

    @include responsive-styles;
  }

  .text-container {
    @extend .children-container;

    h1 {
      color: #fff !important;
      font-weight: 700 !important;
      //letter-spacing: 5px !important;
      filter: brightness(100%) !important;
      text-transform: uppercase !important;
      text-align: center;
      text-shadow: 0px 0px 5px rgba(0, 0, 0, 1);
    }
  }
}
