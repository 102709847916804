.features {
  .cards {
    .item {
      .title {
        min-height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      display: flex;
      align-items: stretch;
    }
  }
}
