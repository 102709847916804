@import "../../assets/styles/variables.scss";
$small: 650px;
$medium: 768px;

.sticky-nav {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-box-shadow: 0 1px 4px rgba(2, 3, 3, 0.15);
  box-shadow: 0 1px 4px rgba(2, 3, 3, 0.15);
}

.priority-nav {
  z-index : 1000;
  width: 30%;

  @media (min-width: $small) {
    width: 50%;
  }

  @media (min-width: $medium) {
    width: 85%;
  }
}

.navbar-default {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background: none;
  border: none;
  padding: 0;
  margin: 0;

  .top-header {
    background-color: #000000;

    .social-wrapper {
      .social-container {
        background-color: #30a5df;
        margin-right: 10px;
        height: 25px;
        width: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: 1s all;
        padding: 10px;
        border-radius: 50%;

        .social-icon {
          color: #fff;
          margin-top: 3px;
        }

        &:hover {
          background-color: lighten(#000000, 30%);
        }
      }
    }
  }

  .container-style {
    max-width: 1110px;
    padding: 2;

    @media (max-width: 767px) {
      padding: 0 20px;
    }
  }

  .navbar-brand {
    img {
      padding: 0px 0;
      width: 200px;
    }
  }
}

@media (max-width: 767px) {
  .navbar-brand {
    img {
      max-height: 90px !important;
    }
  }
}

@media (max-width: 991px) {
  .navbar-default {
    .navbar-nav {
      padding: 20px 10px !important;
    }
  }
}

