@import "assets/styles/variables.scss";

footer {
  background: #f2f2f2;
  margin: 0;
  color: #000000;
  position: relative;

  .container {
    max-width: 1140px;

    .item {
      &:not(:first-child) {
        @media only screen and (max-width: 600px) {
          margin-top: 15px;
        }

        @media only screen and (min-width: 600px) {
          margin-top: 15px;
        }
      }

      p {
        font-size: 15px;

        a {
          text-decoration: none !important;
          color: #000000;

          &:hover {
            color: $swPrimaryColor;
            transition: 1s all;
          }
        }

        i {
          color: $swPrimaryColor;
        }
      }
    }
  }

  .footnote {
    position: absolute;
    bottom: 0 !important;
    background-color: #000000;
  }
}
