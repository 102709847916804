@import "assets/styles/variables.scss";

.purple-section {
  background-color: $swPrimaryColor;

  h3 {
    font-size: 1.3em;
    line-height: 1.5em;
    margin: 0 !important;
  }

  .button-section {
    width: 80%;
    z-index: 1;
    position: absolute;
    text-align: center;
    left: 10%;
  }
}
