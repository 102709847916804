.solvable-priority-nav {
  $smartphone-landscape: 650px;
  $background-color: #4d9ca4;
  $custom-bounce: cubic-bezier(0.25, 2, 0.25, 1);
  $toggle-icon: "data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%236060d6' font-weight='bold' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E";

  nav {
    min-width: 100%;
    white-space: nowrap;
    margin-top: 0 !important;
    float: right;
    height: 100%;
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: end;

    ul {
      display: inline-block;
      list-style-type: none;
      padding: 0;
      margin: 0;
    }

    li {
      display: inline-block;
      &:first-child {
        a {
          padding-left: 0;
        }
      }
    }

    a {
      color: #000000;
      display: inline-block;
      padding: 10px 10px;
      text-decoration: none;
      font-size: 18px;
      cursor: pointer;
    }
  }

  .nav__dropdown {
    &.show {
      transform: rotateX(0deg);
      opacity: 1;
      visibility: visible;
      transition: 300ms $custom-bounce, box-shadow 1000ms $custom-bounce;
    }

    $color: #333333;
    padding: 0.5rem 1.5rem 1rem 0rem;
    white-space: nowrap;
    top: 150%;
    right: 5%;
    background-color: $background-color;
    transition: 300ms $custom-bounce;
    transform: rotateX(-30deg);
    opacity: 0;

    &-wrapper {
      display: inline-block;
      perspective: 500px;
      @media (max-width: $smartphone-landscape) {
        position: static !important;
      }
    }

    &-toggle {
      outline: none;
      display: inline-block;
      border: none;
      background: none;
      color: transparent;
      background-image: url($toggle-icon);
      background-repeat: no-repeat;
      background-position: 100%;
    }

    li {
      display: block;

      &:first-child {
        a {
          padding-left: 2rem;
        }
      }
    }

    a {
      display: inline-block;
      padding: 0.5rem 2rem;
      cursor: pointer;
    }
  }
}
