@import "assets/styles/variables.scss";

.card-item {
  background-color: $swQuintenaryColor;
  border: none;
  font-weight: 400;

  h3 {
    text-transform: uppercase;
    //letter-spacing: 2px;
    color: $swPrimaryColor !important;
    margin: 20px 0 20px 0 !important;
  }

  .icon-container {
    border-radius: 50%;
    height: 62.7px;
    width: 62.7px;
    background-color: $swSectionFeatureColor;

    i {
      color: $swPrimaryColor !important;
      font-size: 24px;
    }
  }
}
